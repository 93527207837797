import logo from './logo.svg';

import './App.css';
import Landing from './Components/Landing/Landing';
import { Toaster } from 'react-hot-toast';
import io from 'socket.io-client';
import { useEffect } from 'react';


function App() {
  const socket = io('http://sanjhavehra.womenempowerment.online/');
  // socket.connect();

  useEffect(() => {
    // Listen for 'initialData' event from the server and update the state
    socket.on('sell_and_auction_history', (data) => {
      console.log("initialData");
    });

    return () => {
      socket.disconnect(); // Clean up WebSocket connection on unmount
    };
  }, []);
  return (
    <div className="content">
  <Toaster />
    <Landing />
    </div>
  );
}

export default App;
