import React, { useEffect, useMemo, useState } from "react";
import "./Landing.css";
import logo from "../../Assets/logo.png";
import swap from "../../Assets/swap.png";
import bnb from "../../Assets/bnb.png";
import BUSD from "../../Assets/BUSD.png";
import USDT from "../../Assets/USDT.png";
import Dogejpg from "../../Assets/Doge.png";
import LTCpng from "../../Assets/LTCpng.png";
import Solana_logo from "../../Assets/Solana_logo.png";
import Eth from "../../Assets/eth.png";

import usd from "../../Assets/usd.png";
import BTCjpg from "../../Assets/BTCjpg.jpg";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsMoon } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { loadWeb3 } from "../apis/api";
import {
  swapContract,
  swapContract_ABI,
  arceContract,
  arceContractAbi,
  arcvContractAbi,
  arcvContract,
  USDTContractAbi,
  USDTContract,
  arcrContract,
  arcmContract,
  arcrContractAbi,
  arcmContractAbi,
  ArwdContractAbi,
  ArwdContract,
  warcContract,
  warcContractAbi,
  Presale_Contract,
  Presale_Abi,
  Presale_Token_Abi,
  Presale_Token_Address,
  BTC_token,
  BTC_token_Abi,
  LTC_token,
  LTC_token_Abi,
  DOGE_token,
  DOGE_token_Abi,
  USDT_token,
  USDT_token_Abi,
  BUSD_token,
  BUSD_token_Abi,
  SOL_token,
  SOL_token_Abi,
} from "../utilies/Contract";
import WalletModal from "../wallet_modal/WalletModal";
import { useDispatch, useSelector } from "react-redux";
import { disconnectWallet } from "../../store/actions/logout";
import Web3 from "web3";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Spin } from "antd";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";

function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <div
          class="react-responsive-modal-modal react-responsive-modal-modalCenter tokens-modal"
          role="dialog"
          aria-modal="true"
          data-testid="modal"
        >
          <div class="Modal__ModalHead-tbaqmu-0 jbvoYk">
            <h2>
              <span>Select Token</span>
            </h2>
          </div>

          <div class="styled__Wrapper-takzv8-0 clBsVQ">
            <div style={{ overflow: "visible", height: "0px", width: "0px" }}>
              <div data-lazyload-listened="8" className="Modl_div">
                <div style={{ height: "auto", width: "100%" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                      onClick={() => {
                        props.setModelOne("ethereum");
                        props.settokenBalanceCopy(Eth);
                        props.setToken_Name("ETH");

                        props.onHide();
                      }}
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src={Eth}
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="Ether"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>ETH</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src={Eth}
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="Ether"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>ETH</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "60px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                      onClick={() => {
                        props.setModelOne("bnb");
                        props.settokenBalanceCopy(
                          "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee/logo.png"
                        );
                        props.setToken_Name("BNB");

                        props.onHide();
                      }}
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="RealToken S 10604 Somerset Ave Detroit MI"
                              style={{ maxWidth: "25px" }}
                            />
                            <span> BNB</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "120px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                    >
                      <div
                        class="styled__Token-takzv8-2 dQaPNl"
                        onClick={() => {
                          props.setModelOne("binance-usd");
                          props.settokenBalanceCopy(
                            "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xe9e7cea3dedca5984780bafc599bd69add087d56/logo.png"
                          );
                          props.setToken_Name("BUSD");

                          props.onHide();
                        }}
                      >
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xe9e7cea3dedca5984780bafc599bd69add087d56/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="AnRKey X"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>BUSD</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "180px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                    >
                      <div
                        class="styled__Token-takzv8-2 dQaPNl"
                        onClick={() => {
                          props.setModelOne("tether");
                          props.onHide();
                          props.settokenBalanceCopy(
                            "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x55d398326f99059ff775485246999027b3197955/logo.png"
                          );

                          props.setToken_Name("USDT");
                        }}
                      >
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x55d398326f99059ff775485246999027b3197955/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="Dosa"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>USDT</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "240px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                    >
                      <div
                        class="styled__Token-takzv8-2 dQaPNl"
                        onClick={() => {
                          props.setModelOne("dogecoin");
                          props.settokenBalanceCopy(
                            "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xba2ae424d960c26247dd6c32edc70b295c744c43/logo.png"
                          );
                          props.setToken_Name("DOGE");

                          props.onHide();
                        }}
                      >
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0xba2ae424d960c26247dd6c32edc70b295c744c43/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="$ROPE"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>DOGE</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "300px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                      onClick={() => {
                        props.setModelOne("litecoin");
                        props.settokenBalanceCopy(
                          "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x4338665cbb7b2485a8855a139b75d5e34ab0db94/logo.png"
                        );
                        props.setToken_Name("LTC");

                        props.onHide();
                      }}
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x4338665cbb7b2485a8855a139b75d5e34ab0db94/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="Strudel Finance"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>LTC</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "360px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                      onClick={() => {
                        props.setModelOne("bitcoin");
                        props.settokenBalanceCopy(
                          "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/logo.png"
                        );
                        props.setToken_Name("BTC");

                        props.onHide();
                      }}
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="0xBitcoin Token"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>BTC</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "420px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <div
                      cursor="pointer"
                      class="styled__TokenWrapper-takzv8-1 kpFIod"
                      onClick={() => {
                        props.setModelOne("solana");
                        props.settokenBalanceCopy(
                          "https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x570a5d26f7765ecb712c0924e4de545b89fd43df/logo.png"
                        );
                        props.setToken_Name("SOL");

                        props.onHide();
                      }}
                    >
                      <div class="styled__Token-takzv8-2 dQaPNl">
                        <div class="token-data">
                          <div class="token-symbol">
                            <img
                              src="https://raw.githubusercontent.com/dappradar/tokens/main/binance-smart-chain/0x570a5d26f7765ecb712c0924e4de545b89fd43df/logo.png"
                              class="sc-khsqcC eFjnvF img-loaded"
                              alt="0xMonero"
                              style={{ maxWidth: "25px" }}
                            />
                            <span>SOL</span>
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="resize-triggers">
              <div class="expand-trigger">
                <div style={{ width: "375px", height: "359px" }}></div>
              </div>
              <div class="contract-trigger"></div>
            </div>
          </div>

          <button
            class="react-responsive-modal-closeButton"
            data-testid="close-button"
            onClick={() => props.onHide()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 36 36"
              data-testid="close-icon"
            >
              <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
            </svg>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Landing() {
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);

  const [modalShow5, setModalShow5] = useState(false);
  const [theme, setTheme] = useState("dark_theme");
  const [iaDark, setIaDark] = useState(false);
  const [BtTxt, setBtTxt] = useState("Connect");
  const [modelOne, setModelOne] = useState("bnb");

  const [Live_Price, setLive_Price] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [Max_Balace, setMax_Balace] = useState("");

  const [tokenBalance, settokenBalance] = useState("");
  const [tokenBalancecOPY, settokenBalanceCopy] = useState(bnb);
  const [Spinner, setSpinner] = useState(false);
  const [getBNB_Value, setgetBNB_Value] = useState("");
  const [Token_Name, setToken_Name] = useState("BNB");
  const [Api_Spinner, setApi_Spinner] = useState(false);

  const showModal = () => {
    setModalShow5(true);
  };
  const closeModal = () => {
    setModalShow5(false);
  };
  const webSupply = new Web3("https://bsc-dataseed1.binance.org");

  const Swap = async () => {
    try {
      setSpinner(true);
      if (!address) {
        toast.error("Connect Wallet First");
        setSpinner(false);
      } else {
        if (getBNB_Value == "") {
          toast.error("Please Enter Token First ! ");
          setSpinner(false);
        } else {
          if (Number(getBNB_Value) > Number(Max_Balace)) {
            toast.error("Insuficent Balace");
            setSpinner(false);
          } else {
            setSpinner(true);
            let value = webSupply.utils.toWei(getBNB_Value.toString());
            let tokenValues = webSupply.utils.toWei(tokenValue.toString());
            if (modelOne == "solana") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapSOLTotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "bnb") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "swapBNBtoToken",
                args: [tokenValues],
                value: [value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              toast.success("Buy Successfully! 🎉");
              setSpinner(false);
            } else if (modelOne == "bitcoin") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapBTCTotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "litecoin") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapLTCTotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "dogecoin") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapDOGETotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "tether") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapUSDTTotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "binance-usd") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "approve",
                args: [Presale_Contract, value],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setTimeout(async () => {
                toast.success("Approved Successfully! 🎉");
                const { request } = await prepareWriteContract({
                  address: Presale_Token_Address,
                  abi: Presale_Token_Abi,
                  functionName: "swapBUSDTotoken",
                  args: [value.toString(), tokenValues],
                  account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                  hash,
                });
                toast.success("Buy Successfully! 🎉");
                setSpinner(false);
              }, 2000);
            } else if (modelOne == "ethereum") {
              const { request } = await prepareWriteContract({
                address: Presale_Token_Address,
                abi: Presale_Token_Abi,
                functionName: "swapEthTotoken",
                args: [value.toString(), tokenValues],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              toast.success("Buy Successfully! 🎉");
              setSpinner(false);
            }

            setSpinner(false);
          }
        }
      }
    } catch (error) {
      setSpinner(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const bnbtoToken = async () => {
    try {
      if (address) {
        if (getBNB_Value == "") {
          setTokenValue("");
        } else {
          setApi_Spinner(true);
        }
        const webSupply = new Web3("https://bsc-dataseed1.binance.org");
        let Presale_ContractOf = new webSupply.eth.Contract(
          Presale_Abi,
          Presale_Contract
        );
        let BUSD_ContractOf = new webSupply.eth.Contract(
          Presale_Token_Abi,
          Presale_Token_Address
        );

        let res = await axios.get(
          `https://archievault.archievault.net/Live_Rate?Coin=${modelOne}`
        );

        res = res?.data?.data?.data;
        // console.log("Check", res);
        let balanceOf;
        if (modelOne == "usd-coin") {
          var { 3408: number } = res;
          let BUSD_ContractOf = new webSupply.eth.Contract(
            Presale_Token_Abi,
            Presale_Token_Address
          );
          balanceOf = await BUSD_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "bnb") {
          var { 1839: number } = res;
          let BNBBalnace = await webSupply.eth.getBalance(address);
          BNBBalnace = webSupply.utils.fromWei(BNBBalnace, "ether");
          balanceOf = parseFloat(BNBBalnace).toFixed(4);
          console.log("BNBBalnace", balanceOf);
        } else if (modelOne == "bitcoin") {
          var { 1: number } = res;
          let BTC_ContractOf = new webSupply.eth.Contract(
            BTC_token_Abi,
            BTC_token
          );
          balanceOf = await BTC_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "litecoin") {
          var { 2: number } = res;
          let LTC_ContractOf = new webSupply.eth.Contract(
            LTC_token_Abi,
            LTC_token
          );
          balanceOf = await LTC_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "dogecoin") {
          var { 74: number } = res;
          let DOGE_ContractOf = new webSupply.eth.Contract(
            DOGE_token_Abi,
            DOGE_token
          );
          balanceOf = await DOGE_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "tether") {
          var { 825: number } = res;
          let USDT_ContractOf = new webSupply.eth.Contract(
            USDT_token_Abi,
            USDT_token
          );
          balanceOf = await USDT_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "binance-usd") {
          var { 4687: number } = res;
          let BUSD_ContractOf = new webSupply.eth.Contract(
            BUSD_token_Abi,
            BUSD_token
          );
          balanceOf = await BUSD_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "solana") {
          var { 5426: number } = res;
          let SOL_ContractOf = new webSupply.eth.Contract(
            SOL_token_Abi,
            SOL_token
          );
          balanceOf = await SOL_ContractOf.methods.balanceOf(address).call();
        } else if (modelOne == "ethereum") {
          var { 1027: number } = res;
          let BNBBalnace = await webSupply.eth.getBalance(address);
          BNBBalnace = webSupply.utils.fromWei(BNBBalnace, "ether");
          balanceOf = parseFloat(BNBBalnace).toFixed(4);
        }

        setMax_Balace(balanceOf);
        settokenBalance(balanceOf.slice(0, 10));
        let Token_Price = number?.quote?.USD?.price;
        setLive_Price(Token_Price);
        let value = parseFloat(Token_Price * getBNB_Value).toFixed(2);

        let token_value = webSupply.utils.toWei(value.toString());
        let getValue = await Presale_ContractOf.methods
          .CalculateToken(token_value.toString())
          .call();

        getValue = webSupply.utils.fromWei(getValue.toString());

        setTokenValue(getValue);
        setApi_Spinner(false);
      } else {
        toast.error("Connect Wallet First");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (getBNB_Value == "") {
      setTokenValue("");
    }
    if(address){

      bnbtoToken();
    }
  }, [getBNB_Value, modelOne, address]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div className="maain">
      <WalletModal show={modalShow5} onHide={closeModal} />

      <div className=" dkiaqS">
        <div className="container">
          <div className="sc-bsatvv jnCyRr">
            <div class="sc-kiPvrU bLuxRJ">
              <div class="sc-jsTgWu flregt">
                <a
                  href="/"
                  title="Go to homepage"
                  style={{ textDecoration: "none" }}
                >
                  <img style={{
    width: "7rem",
    marginTop: "-.7rem"}}
    src="../digiswap.png"  />
                </a>
              </div>
              <div class="sc-elAWhN iqApcS mobile-only"></div>
            </div>

            <div className="d-flex">
              <div class="me-3">
                <div class=""></div>
                <div class="">
                  <div class="header_btmtn">
                    <ConnectButton
                      chainStatus="none"
                      showBalance={{ smallScreen: false, largeScreen: false }}
                      className="sc-cTVMo eAEdIS connect-text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="styled__SwapContainer-sc-19g5mt1-2 iAGiSE pt-5">
          <div class="styled__SwapBox-sc-19g5mt1-3 cPATHV">
            <div class="Header__Container-sc-1fo7bsq-0 iEritK">
              <h1>
                <span>Token swap</span>
              </h1>
            </div>
            <div>
              <div class="Input__Wrapper-rkhhwu-0 jHLanH pb-5">
                <div class="Input__Container-rkhhwu-1 iLgxGK">
                  <div class="Input__TopRow-rkhhwu-2 juquYd">
                    <div>From</div>
                    <div>Balance:{tokenBalance} </div>
                  </div>
                  <div class="Input__BottomRow-rkhhwu-3 damFGr">
                    <input
                      title="Token Amount"
                      type="number"
                      placeholder="0.0"
                      class="Input__Amount-rkhhwu-4 cPXndw"
                      onChange={(e) => setgetBNB_Value(e.target.value)}
                      defaultValue={getBNB_Value}
                    />
                    <button
                      type="button"
                      class="Input__MaxButton-rkhhwu-5 gXretN"
                      onClick={() =>
                        setgetBNB_Value(
                          modelOne == "bnb"
                            ? Number(Max_Balace) - Number(0.05)
                            : Max_Balace
                        )
                      }
                    >
                      Max
                    </button>
                    <button
                      type="button"
                      class="TokenButton__ButtonStyle-sc-4qap0e-0 khdiWX"
                      onClick={() => setModalShow2(true)}
                    >
                      <img
                        src={tokenBalancecOPY}
                        class="sc-khsqcC eFjnvF img-loaded"
                        alt="Ether"
                        style={{ width: "25px", height: "25px" }}
                      />
                      <span>{Token_Name}</span>
                    </button>
                  </div>
                  <MyVerticallyCenteredModal2
                    show={modalShow2}
                    setModelOne={setModelOne}
                    onHide={() => setModalShow2(false)}
                    settokenBalanceCopy={settokenBalanceCopy}
                    setToken_Name={setToken_Name}
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="Input__Wrapper-rkhhwu-0 jHLanH mt-3">
                <Spin tip="Loading..." spinning={Api_Spinner}>
                  <div class="Input__Container-rkhhwu-1 iLgxGK">
                    <div class="Input__TopRow-rkhhwu-2 juquYd">
                      <div>To</div>
                      <div></div>
                    </div>
                    <div class="Input__BottomRow-rkhhwu-3 damFGr">
                      <input
                        title="Token Amount"
                        type="text"
                        placeholder="0.0"
                        class="Input__Amount-rkhhwu-4 cPXndw"
                        value={tokenValue}
                      />
                      <button
                        type="button"
                        class="TokenButton__ButtonStyle-sc-4qap0e-0 khdiWX1"
                      >
                        <img className="image" src='https://token.digifortune.net/assets/imgs/favicon.png' />
                        <span>DIFO</span>
                      </button>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
            {address ? (
              <>
                <button
                  type="button"
                  width="100%"
                  class="sc-dkrFOg jBlWbQ"
                  onClick={() => Swap()}
                >
                  {Spinner ? (
                    <>
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>{!address ? "Connect Wallet" : "Swap"}</>
                  )}
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  width="100%"
                  class="sc-dkrFOg jBlWbQ"
                  onClick={() => openConnectModal()}
                >
                  {Spinner ? (
                    <>
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>{!address ? "Connect Wallet" : "Swap"}</>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
